import {
    Test_GET,
    QueryDateAndType_GET,
    OrderMeal_POST,
    SubmitMeal_POST,
    UserCancel_POST,
    OrderMealList_GET,
    AllList_GET,
    ChargeOffList_GET,
    CanceledList_GET,
    ExpiredList_GET,
    UserQuery_GET,
    AdminChargeOff_POST,
    AdminCancel_POST,
    ExportExcel_POST,
    QRCodeImage_GET,
    GetAuthority_GET,
    CheckSucceed_GET
} from '@/api/app'
import {
    request,
    exportFile,
    METHOD
} from '@/utils/request'

export async function getTestInfo(data) {
    return request(Test_GET, METHOD.GET, data)
}
//OrderMeal
export async function getDateAndTypeInfo(phone) {
    return request(QueryDateAndType_GET, METHOD.GET, {
        phone: phone
    })
}
export async function postOrderMeal(data) {
    return request(OrderMeal_POST, METHOD.POST, data)
}

export async function submitMeal(data) {
    return request(SubmitMeal_POST, METHOD.POST, data)
}
export async function getOrderList(data) {
    return request(OrderMealList_GET, METHOD.GET, data)
}
export async function getUser(data) {
    return request(UserQuery_GET, METHOD.GET, data)
}

//QR
export async function qrCodeImage(data) {
    return request(QRCodeImage_GET, METHOD.GET, data, {
        responseType: 'blob'
    })
}
// export async function qrCode(data) {
//     return request(QRCode_GET, METHOD.GET, data)
// }

// export async function getReceive() {
//     return request(QRGetReceive_POST, METHOD.POST)
// }

export async function adminChargeOff(data) {
    return request(AdminChargeOff_POST, METHOD.POST, data)
}
export async function adminCancel(data) {
    return request(AdminCancel_POST, METHOD.POST, data)
}

export async function userCancel(id) {
    return request(UserCancel_POST, METHOD.POST, id)
}
export async function allList(data) {
    return request(AllList_GET, METHOD.GET, data)
}

export async function chargeOffList(data) {
    return request(ChargeOffList_GET, METHOD.GET, data)
}

export async function canceledList(data) {
    return request(CanceledList_GET, METHOD.GET, data)
}

export async function expiredList(data) {
    return request(ExpiredList_GET, METHOD.GET, data)
}

export async function checkSucceed(data) {
    return request(CheckSucceed_GET, METHOD.GET, data)
}

//File
export async function exportExcel(data) {
    return exportFile(ExportExcel_POST, data, METHOD.POST)
}

// //Config
// export async function getAll() {
//     return request(GetAll_POST, METHOD.POST)
// }

// export async function updateAll(data) {
//     return request(UpdateAll_POST, METHOD.POST, data)
// }

export async function getAuthority(data) {
    return request(GetAuthority_GET, METHOD.GET, data)
}