//跨域代理前缀

/**开发环境 */
//const API_PROXY_PREFIX = 'http://localhost:44390/api'
//const API_PROXY_PREFIX = 'https://localhost:5001/api'
//const API_PROXY_PREFIX = 'https://ordermeal.rpc-asia.com/api/api'
//const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : API_PROXY_PREFIX

//跨域代理前缀
const API_PROXY_PREFIX = '/api'
/**正式环境 */
const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : API_PROXY_PREFIX
module.exports = {
    //OrderMeal
    Test_GET: `${BASE_URL}/OrderMeal/Get`,
    QueryDateAndType_GET: `${BASE_URL}/OrderMeal/GetDateAndType`,
    OrderMeal_POST: `${BASE_URL}/OrderMeal/OrderMeal`,
    OrderMealList_GET: `${BASE_URL}/OrderMeal/GetOrderByRowStatus`,
    UserQuery_GET: `${BASE_URL}/OrderMeal/GetUser`,
    AllList_GET: `${BASE_URL}/OrderMeal/AllList`,

    SubmitMeal_POST: `${BASE_URL}/OrderMeal/OrderMeal`,
    //Qr
    QRCodeImage_GET: `${BASE_URL}/QR/GetQrcodeImage`,
    QRCode_GET: `${BASE_URL}/QR/NewQRCodeByZxingNet`,
    QRGetReceive_POST: `${BASE_URL}/QR/GetReceive`,
    AdminChargeOff_POST: `${BASE_URL}/QR/AdminChargeOff`,
    AdminCancel_POST: `${BASE_URL}/QR/AdminCancel`,
    UserCancel_POST: `${BASE_URL}/QR/UserCancel`,

    ChargeOffList_GET: `${BASE_URL}/QR/ChargeOffList`,
    CanceledList_GET: `${BASE_URL}/QR/CanceledList`,
    ExpiredList_GET: `${BASE_URL}/QR/ExpiredList`,
    CheckSucceed_GET: `${BASE_URL}/QR/CheckSucceed`,
    //File
    ExportExcel_POST: `${BASE_URL}/File/ExportExcel`,

    //Config
    GetAll_GET: `${BASE_URL}/Config/GetAll`,
    UpdateAll_POST: `${BASE_URL}/Config/UpdateAll`,
    GetAuthority_GET: `${BASE_URL}/Config/GetAuthority`
}