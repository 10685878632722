import axios from 'axios'
import Cookie from 'js-cookie'

// 跨域认证信息 header 名
const xsrfHeaderName = 'Authorization'
const token = 'Token'

axios.defaults.timeout = 300000
// axios.defaults.withCredentials= true
axios.defaults.xsrfHeaderName = xsrfHeaderName
axios.defaults.xsrfCookieName = xsrfHeaderName

// 认证类型
const AUTH_TYPE = {
    BEARER: 'Bearer',
    BASIC: 'basic',
    AUTH1: 'auth1',
    AUTH2: 'auth2',
}

// http method
const METHOD = {
    GET: 'get',
    POST: 'post'
}

/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function request(url, method, params, config) {
    axios.defaults.headers.common['token'] = Cookie.get(token)
    switch (method) {
        case METHOD.GET:
            // get请求添加时间戳 防止缓存
            if (params) {
                params.timeStamp = new Date().getTime();
            }
            return axios.get(url, {
                params,
                ...config
            })
        case METHOD.POST:
            return axios.post(url, params, config)
        default:
            return axios.get(url, {
                params,
                ...config
            })
    }
}

/**
 * 导出文件
 * @param url 请求地址
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function exportFileType(url, params, type) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
        axios.defaults.headers.common['token'] = Cookie.get(token)
        type = 'post';
        console.log(params);
        var param;
        if (type === 'get') {
            param = {
                method: type,
                url: url,
                params,
                responseType: 'blob'
            }
        } else {
            param = {
                method: type,
                url: url,
                data: params,
                responseType: 'blob'
            }
        }

        axios(param).then(
            response => {
                resolve(response.data)
                let blob = new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                    // type: 'application/pdf'
                })
                // let fileName = Date.parse(new Date()) + '.xlsx'
                let contentDisposition = response.headers['content-disposition'];
                //console.log(contentDisposition);
                // fileName必用这种方式进行解析，否则乱码
                let fileName = params.fileName;
                if (contentDisposition) {
                    fileName = window.decodeURI(contentDisposition.substring(contentDisposition.indexOf(
                        '=UTF-8\'\'') + 8));
                }
                if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, fileName)
                } else {
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = fileName;
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                }
            },
            err => {
                reject(err)
            }
        )
    })
}

/**
 * 导出文件，get方式
 * @param url 请求地址
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function exportFile(url, params, method) {
    return exportFileType(url, params, method);
}

/**
 * 设置认证信息
 * @param auth {Object}
 * @param authType {AUTH_TYPE} 认证类型，默认：{AUTH_TYPE.BEARER}
 */
function setAuthorization(auth, authType = AUTH_TYPE.BEARER) {
    switch (authType) {
        case AUTH_TYPE.BEARER:
            Cookie.set(xsrfHeaderName, 'Bearer ' + auth.token, {
                expires: auth.expireAt
            })
            Cookie.set(token, auth.token, {
                expires: auth.expireAt
            })
            break
        case AUTH_TYPE.BASIC:
        case AUTH_TYPE.AUTH1:
        case AUTH_TYPE.AUTH2:
        default:
            break
    }
}

/**
 * 移出认证信息
 * @param authType {AUTH_TYPE} 认证类型
 */
function removeAuthorization(authType = AUTH_TYPE.BEARER) {
    switch (authType) {
        case AUTH_TYPE.BEARER:
            Cookie.remove(xsrfHeaderName)
            Cookie.remove(token)
            break
        case AUTH_TYPE.BASIC:
        case AUTH_TYPE.AUTH1:
        case AUTH_TYPE.AUTH2:
        default:
            break
    }
}

/**
 * 检查认证信息
 * @param authType
 * @returns {boolean}
 */
function checkAuthorization(authType = AUTH_TYPE.BEARER) {
    switch (authType) {
        case AUTH_TYPE.BEARER:
            if (Cookie.get(xsrfHeaderName)) {
                return true
            }
            break
        case AUTH_TYPE.BASIC:
        case AUTH_TYPE.AUTH1:
        case AUTH_TYPE.AUTH2:
        default:
            break
    }
    return false
}

/**
 * 加载 axios 拦截器
 * @param interceptors
 * @param options
 */
function loadInterceptors(interceptors, options) {
    const {
        request,
        response
    } = interceptors
    // 加载请求拦截器
    request.forEach(item => {
        let {
            onFulfilled,
            onRejected
        } = item
        if (!onFulfilled || typeof onFulfilled !== 'function') {
            onFulfilled = config => config
        }
        if (!onRejected || typeof onRejected !== 'function') {
            onRejected = error => Promise.reject(error)
        }
        axios.interceptors.request.use(
            config => onFulfilled(config, options),
            error => onRejected(error, options)
        )
    })
    // 加载响应拦截器
    response.forEach(item => {
        let {
            onFulfilled,
            onRejected
        } = item
        if (!onFulfilled || typeof onFulfilled !== 'function') {
            onFulfilled = response => response
        }
        if (!onRejected || typeof onRejected !== 'function') {
            onRejected = error => Promise.reject(error)
        }
        axios.interceptors.response.use(
            response => onFulfilled(response, options),
            error => onRejected(error, options)
        )
    })
}

/**
 * 解析 url 中的参数
 * @param url
 * @returns {Object}
 */
function parseUrlParams(url) {
    const params = {}
    if (!url || url === '' || typeof url !== 'string') {
        return params
    }
    const paramsStr = url.split('?')[1]
    if (!paramsStr) {
        return params
    }
    const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
    for (let i = 0; i < paramsArr.length / 2; i++) {
        const value = paramsArr[i * 2 + 1]
        params[paramsArr[i * 2]] = value === 'true' ? true : (value === 'false' ? false : value)
    }
    return params
}

export {
    METHOD,
    AUTH_TYPE,
    request,
    exportFile,
    exportFileType,
    setAuthorization,
    removeAuthorization,
    checkAuthorization,
    loadInterceptors,
    parseUrlParams
}